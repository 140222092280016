<template>
  <div class="flex mb-32 pt-10">
    <div class="w-full sm:w-2/3 md:w-3/5 lg:w-4/5 xl:w-2/3 2xl:w-1/2 mx-auto">
      <div class="flex gap-10 items-center mb-5">
        <h1 class="text-2xl font-bold">User Onboarding</h1>
        <router-link :to="{ name: 'users-new' }" class="ml-auto text-sm text-gray-700">
          + Add new user
        </router-link>
      </div>

      <div
        class="
          card
          border border-blue-200
          pt-16
          pb-10
          px-6
          md:px-16
          -mx-6
          sm:mx-0
        "
      >
        <fetch v-if="!selectedUser" @loaded="loaded" />
        <template v-else>
          <div class="mb-5">
            <button
              type="button"
              class="btn btn-link px-0 text-blue-500"
              @click.prevent="clearUser"
            >
              <span class="flex mr-2">
                <ion-icon name="arrow-back-outline"></ion-icon>
              </span>
              <span>Select a different user</span>
            </button>
          </div>
          <profile :selected-user="selectedUser" @updated="updated" />
          <div class="border-t border-blue-200 my-10"></div>

          <template>
            <employed
              v-if="isEmployed"
              :selected-user="selectedUser"
              @updated="updated"
            />

            <self-employed
              v-else-if="isSelfEmployed"
              :selected-user="selectedUser"
              @updated="updated"
            />

            <div v-else class="border border-blue-200 p-5 rounded"></div>
          </template>
          <div class="border-t border-blue-200 my-10"></div>
          <documents :selected-user="selectedUser" @updated="updated" />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    Documents: () => import("./Documents"),
    Employed: () => import("./Employed"),
    SelfEmployed: () => import("./SelfEmployed"),
    Fetch: () => import("./Fetch"),
    Profile: () => import("./Profile"),
  },
  data() {
    return {
      form: this.$options.basicForm(["selector", "selector_type"]),
      selectedUser: null,
    };
  },
  computed: {
    employmentStatus() {
      return this.selectedUser?.profile?.employment_status;
    },
    isEmployed() {
      return this.employmentStatus === "employed";
    },
    isSelfEmployed() {
      return this.employmentStatus === "self_employed";
    },
    isEmploymentStatusNotSet() {
      return !this.employmentStatus;
    },
  },
  methods: {
    clearUser() {
      this.$router.push({ ...this.$route, query: null }).then(() => {
        this.selectedUser = null;
      });
    },
    loaded(user) {
      this.selectedUser = user;
      if (Number(this.$route.query.userId) !== Number(user?.id)) {
        this.$router.push({ ...this.$route, query: { userId: user?.id } });
      }
    },
    updated(user) {
      this.selectedUser = {
        ...this.selectedUser,
        ...user,
      };
    },
  },
};
</script>
